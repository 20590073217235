import React from 'react';
import Navbar from '../Navigation/Navbar';

const TermsAndPrivacy = () => {
    return (
        <>
            <Navbar />
            <div className='container'>
                <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                    <h1>Terms of Use and Privacy Policy</h1>

                    {/* Terms of Use Section */}
                    <h2>Terms of Use</h2>
                    <p>
                        Scribus is a Software as a Service (SaaS) operated by Fabien BOCO, a French auto-entrepreneur,
                        located in Colomiers, 31770 France.
                    </p>
                    <p>
                        In accordance with Article 6 of Law No. 2004-575 of June 21, 2004, on confidence in the digital economy,
                        users of the <b>scribus.ai</b> website are informed of the identity of the various parties involved in its creation and monitoring:
                    </p>
                    <ul>
                        <li><b>Business Name</b>: BOCO</li>
                        <li><b>RCS (Trade and Companies Register) Number</b>: 095311082</li>
                        <li><b>Status</b>: Sole Proprietorship</li>
                        <li><b>SIRET Number</b>: 89531108200013</li>
                        <li><b>Address</b>: 31770 Colomiers, France</li>
                        <li><b>Phone Number</b>: 0756835116</li>
                        <li><b>Email</b>: contact@scribus.ai</li>
                    </ul>

                    <h3>Hosting Service Provider</h3>
                    <p>
                        The Scribus website is hosted by o2switch, located at 222-224 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France.
                    </p>

                    <h3>Processor of Payment</h3>
                    <p>
                        Payment processing services for Scribus are provided by Stripe and are subject to the Stripe Connected Account Agreement,
                        which includes the Stripe Terms of Service. By agreeing to these terms or continuing to operate as a Scribus customer,
                        you agree to be bound by the Stripe Services Agreement, as it may be modified by Stripe from time to time.
                    </p>

                    <h3>Use of Service</h3>
                    <p>
                        Scribus provides a Software as a Service that allows users to generate written content using AI.
                        Users may sign up and pay for a subscription to access unlimited use of the service. Once a subscription is purchased,
                        it is non-refundable unless otherwise determined by Scribus' support team.
                    </p>

                    <h3>Availability</h3>
                    <p>
                        Scribus's service is available for use and purchase worldwide.
                    </p>

                    <h3>Contact Information</h3>
                    <p>
                        For any questions or comments regarding these legal notices, please contact us at <b>contact@scribus.ai</b>.
                    </p>

                    <h3>Prohibited Use of Scribus</h3>
                    <p>
                        Use of Scribus must be lawful and in accordance with these Terms of Use. Any use of the platform that violates these terms, including, without limitation, the following, is expressly prohibited:
                    </p>
                    <ul>
                        <li><b>Unauthorized Automation</b>: The use of bots, scripts, or any other automated processes to interact with Scribus's services or to automate or accelerate content generation is strictly forbidden.</li>
                        <li><b>Breach of Normal Functionality</b>: Actions that disrupt or interfere with Scribus's services, such as imposing an unreasonable or disproportionately large load on our infrastructure, are prohibited.</li>
                        <li><b>Penalties for Violation</b>: In case of violation, Scribus reserves the right to restrict or suspend access to the service without prior notice. No refunds will be issued for any payments made prior to the violation.</li>
                    </ul>

                    <h3>Modification of Subscription Privileges</h3>
                    <p>
                        Subscription privileges may change. Scribus will notify you via email at least 15 days prior to any significant changes in subscription terms.
                        Please ensure email notifications are not marked as spam to receive these updates.
                    </p>

                    <h3>Limitation of Liability</h3>
                    <p>
                        To the fullest extent permitted by law, Scribus will not be liable for any damages arising from the use of the Site or the Scribus Services, including but not limited to direct, indirect, incidental, punitive, and consequential damages.
                    </p>

                    <h3>Termination</h3>
                    <p>
                        Scribus may terminate your access to the Site and the Scribus Services at any time for any reason, including but not limited to a breach of these Terms of Use.
                    </p>

                    <h3>Governing Law</h3>
                    <p>
                        These Terms of Use and any disputes arising out of or related to the Site or the Scribus Services will be governed by and construed in accordance with the laws of France.
                    </p>

                    <h3>Changes to These Terms</h3>
                    <p>
                        Scribus may modify these Terms of Use at any time by posting the modified terms on the Site. Continued use of the Site and Scribus Services after such modifications constitutes acceptance of the modified terms.
                    </p>

                    {/* Privacy Policy Section */}
                    <h2>Privacy Policy</h2>
                    <p>
                        Last updated: 08 October 2024
                    </p>

                    <p>
                        Scribus ("we", "us", "our") is committed to protecting the privacy of our users ("you", "your").
                        This Privacy Policy explains how we collect, use, disclose, and safeguard your information
                        when you visit our website and use our AI-driven content generation service.
                    </p>

                    <h3>Information We Collect</h3>
                    <p>
                        When you register for an account on Scribus, we may collect the following information:
                    </p>
                    <ul>
                        <li>Email address</li>
                        <li>Encrypted password</li>
                    </ul>
                    <p>
                        Payments are processed using Stripe. Please review Stripe’s Privacy Policy to understand how they collect and process your information.
                    </p>

                    <h3>How We Use Your Information</h3>
                    <p>We use the information collected for purposes such as:</p>
                    <ul>
                        <li>Providing, operating, and maintaining the Scribus Service</li>
                        <li>Improving, personalizing, and expanding the Service</li>
                        <li>Communicating with you, including customer service and support</li>
                        <li>Processing transactions</li>
                        <li>Sending updates, promotional materials, and other information related to the Service</li>
                    </ul>

                    <h3>How We Protect Your Information</h3>
                    <p>
                        We implement various security measures to maintain the safety of your personal information.
                        This information is stored on secured networks, accessible only to a limited number of individuals with special access rights.
                    </p>

                    <h3>Data Retention</h3>
                    <p>
                        We retain your personal information as long as necessary to provide the Scribus Service and fulfill requested transactions,
                        as well as for legal compliance and fraud prevention.
                    </p>

                    <h3>User Generated Content</h3>
                    <p>
                        When you use Scribus to generate text content, we store this generated content in our database. We do not share your content with third parties.
                        However, we may use this data to improve the Service, such as refining our algorithms and enhancing the overall user experience.
                    </p>

                    <h3>Third-Party Services</h3>
                    <p>
                        We may use third-party services to help operate and improve our Service. These third parties may have access to your information only to perform specific tasks on our behalf and are obligated not to disclose or use your information for any other purpose.
                    </p>

                    <h3>Children's Privacy</h3>
                    <p>
                        Our Service is not intended for children under 13. If we become aware of data collected from children under 13,
                        we will promptly delete it and terminate the child's account.
                    </p>

                    <h3>Changes to This Privacy Policy</h3>
                    <p>
                        We may update our Privacy Policy from time to time. Updates will be posted on this page,
                        and the "Last updated" date will be revised. We encourage you to periodically review this Privacy Policy for changes.
                    </p>

                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at:</p>
                    <p><b>Email</b>: contact@scribus.ai</p>
                </div>
            </div>
        </>
    );
};

export default TermsAndPrivacy;
