import { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import api from '../api';
import LoadingButton from '../UIComponent/LoadingButton';


const TableValidation = ({ projectId, onTableValidation }) => {

    const [editor] = useLexicalComposerContext();
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const validate = () => {

        setIsButtonLoading(true);

        // Récupérer le contenu de l'éditeur au format JSON
        editor.update(() => {
            const editorState = editor.getEditorState();
            const content = JSON.stringify(editorState.toJSON());

            // Appeler la route /update_content avec Axios
            api.post('/update_content', {
                projectId: projectId,
                content: content
            })
                .then((response) => {
                    console.log(response.data.message); // Afficher la réponse du serveur
                    onTableValidation(projectId); // Appeler la fonction callback
                })
                .catch((error) => {
                    console.error('Erreur lors de la validation :', error);
                })
                .finally(() => {
                    setIsButtonLoading(false); // Arrête le chargement après la réponse
                });
        });
    };

    return (
        <div className='navigation-buttons table-validation'>
            <LoadingButton className="button-plain " isLoading={isButtonLoading} onClick={validate}>
                Validate
                <span className="material-symbols-outlined">arrow_forward_ios</span>
            </LoadingButton>
        </div>
    )
};

export default TableValidation;