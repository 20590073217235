import React, { useState, useEffect } from 'react';
import './WarningPopup.css'

const WarningPopup = ({ children, popupName  }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const isDismissed = localStorage.getItem(popupName);
        if (!isDismissed) {
            setIsVisible(true);
        }
    }, []);

    const handleDismiss = () => {
        setIsVisible(false);
        localStorage.setItem(popupName, 'true');
    };

    if (!isVisible) return null;

    return (
        <div className="overlay warning-popup-mobile" onClick={(e) => e.stopPropagation()}>
            <div className="popup warning-popup">
                <p>{children}</p>
                <button className="button-plain" onClick={handleDismiss}>
                    Dismiss
                </button>
            </div>
        </div>
    );
};

export default WarningPopup;