import React, { useEffect, useState } from 'react';
import './Typewriter.css'; // Ajoute ce fichier CSS pour les animations

const Typewriter = ({ texts, typeSpeed = 100, deleteSpeed = 50, period = 2000 }) => {
    const [currentText, setCurrentText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [delta, setDelta] = useState(typeSpeed); // Contrôle la vitesse de frappe
    const [cursor, setCursor] = useState(true); // Pour le curseur clignotant

    useEffect(() => {
        const tick = () => {
            const i = loopNum % texts.length;
            const fullText = texts[i];

            setCurrentText(prevText => {
                if (isDeleting) {
                    return fullText.substring(0, prevText.length - 1);
                } else {
                    return fullText.substring(0, prevText.length + 1);
                }
            });

            setDelta(isDeleting ? deleteSpeed : typeSpeed); // Contrôle la vitesse de suppression et de frappe

            if (!isDeleting && currentText === fullText) {
                setIsDeleting(true);
                setDelta(period); // Pause avant de commencer à supprimer
            } else if (isDeleting && currentText === '') {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
                setDelta(500); // Pause avant de commencer à écrire le texte suivant
            }
        };

        const ticker = setTimeout(tick, delta);
        return () => clearTimeout(ticker);
    }, [currentText, isDeleting, delta, loopNum, texts, typeSpeed, deleteSpeed, period]);

    // Animation du curseur clignotant
    useEffect(() => {
        const cursorBlink = setInterval(() => {
            setCursor(prevCursor => !prevCursor);
        }, 500);
        return () => clearInterval(cursorBlink);
    }, []);

    return (
        <h1 className="typewrite">
            <span className="wrap">{currentText}</span>
            <span className="cursor">{cursor ? '|' : '\u00A0'}</span>
        </h1>
    );
};

export default Typewriter;
