import { create } from 'zustand';

const textCounter = create((set) => ({
  wordCount: 0,
  charCount: 0,
  setWordCount: (count) => set({ wordCount: count }),
  setCharCount: (count) => set({ charCount: count }),
}));

export default textCounter;
