import React, { useState } from 'react';
import unidecode from 'unidecode';
import './ExportPopup.css';
import api from '../api';

const ExportPopup = ({ project, onClose }) => {

    const [format, setFormat] = useState('docx');
    const [isExporting, setIsExporting] = useState(false);

    const handleFormatToggle = (selectedFormat) => {
        setFormat(selectedFormat);
    };

    const sanitizeTitle = (title) => {

        let sanitizedTitle = unidecode(title);
        sanitizedTitle = sanitizedTitle.replace(/[^\w\-_\. ]/g, '');
        sanitizedTitle = sanitizedTitle.trim().replace(/\s+/g, '_');

        return sanitizedTitle;
    };

    const handleExport = async () => {
        if (!project.id) return;

        setIsExporting(true);
        try {
            const response = await api.post(
                '/export',
                { project_id: project.id, format: format }, // Envoie le format sélectionné au backend
                { responseType: 'blob' } // Important pour récupérer le fichier en tant que blob
            );

            const cleanTitle = sanitizeTitle(project.name);

            // Créer un lien caché et déclencher le téléchargement
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${cleanTitle}.${format}`); // Définir le nom de fichier en fonction du format
            document.body.appendChild(link);
            link.click();
            link.remove(); // Nettoyage du DOM après le téléchargement
        } catch (error) {
            console.error("Erreur lors de l'export : ", error);

        } finally {
            setIsExporting(false);
            onClose();
        }
    };


    // const handleOverlayClick = (e) => {
    //     if (e.target.classList.contains('overlay')) {
    //         onClose(false);
    //         setMessage(null);
    //     }
    // };

    return (
        <div className="overlay" onClick={onClose}>
            <div className="export-popup popup" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>&times;</button>
                <h3>Select the file type</h3>
                <div className="format-toggle">
                    <button
                        className={`format-button ${format === 'txt' ? 'active' : ''}`}
                        onClick={() => handleFormatToggle('txt')}
                    >
                        .TXT
                    </button>
                    <button
                        className={`format-button ${format === 'docx' ? 'active' : ''}`}
                        onClick={() => handleFormatToggle('docx')}
                    >
                        .DOCX
                    </button>
                    <button
                        className={`format-button ${format === 'pdf' ? 'active' : ''}`}
                        onClick={() => handleFormatToggle('pdf')}
                    >
                        .PDF
                    </button>
                </div>
                <p>You can now download your essay in .{format} format</p>
                <button
                    className="download-button"
                    onClick={handleExport}
                    disabled={isExporting}
                >
                    {isExporting ? <span className="material-symbols-outlined">hourglass_top</span> : <span className="material-symbols-outlined">download</span>}
                    {isExporting ? 'Exporting...' : 'Download file'}
                </button>
            </div>
        </div>
    );
};

export default ExportPopup;
