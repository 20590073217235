import React, { useState, useContext } from 'react';
import { AuthContext } from '../Login/AuthContext';
import Navbar from '../Navigation/Navbar';
import api from '../api'; // Assuming you have an `api` setup for making HTTP requests
import './Support.css';

const Support = () => {
    const { user } = useContext(AuthContext);  // Access user info from AuthContext
    const [formData, setFormData] = useState({
        name: '',
        email: user ? user.email : '',
        message: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const response = await api.post('/support', formData);
            setMessage(response.data.msg);  // Show confirmation message
        } catch (error) {
            console.error('Error sending support request:', error);
            setError('Something went wrong. Please try again later.');
        }
    };

    return (
        <>
            <Navbar />
            <div className="support-page">
                <h1>Have a question? Ask the Scribus team!</h1>
                <p>For support only.</p>

                <form onSubmit={handleSubmit} className="support-form">
                    <div className="input-group">
                        <div className="icon-container">
                            <span className="material-symbols-outlined icon">person</span>
                        </div>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <div className="icon-container">
                            <span className="material-symbols-outlined icon">mail</span>
                        </div>
                        <input
                            type="email"
                            name="email"
                            placeholder="yours@example.com"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <textarea
                            id="question"
                            name="message"
                            placeholder='Your question'
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>

                    <button type="submit" className="button-plain">Send</button>

                    {message && <p className="success-message">{message}</p>}
                    {error && <p className="error-message">{error}</p>}
                </form>


            </div>
        </>
    );
};

export default Support;
