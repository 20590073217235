import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContext';

import api from '../api';
import './User.css'

const User = ({ onClose }) => {

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleCancel = async () => {

        try {
            const response = await api.get(`/stripe_dashboard`);
            window.location.href = response.data.dashboard_url;
        } catch (error) {
            console.error('Stripe:', error);
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds if it's in seconds
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    // Gérer le clic en dehors du popup pour le fermer
    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            onClose(false);
        }
    };

    return (

        <div className="overlay" onClick={handleOverlayClick}>
            <div className="popup user-panel">
                <h2>Manage your subscription</h2>
                <p>Add your email and create a password to create a new account</p>

                <div className='user-infos'>
                    <div>
                        <span className='user-email'>{user.email}</span>

                    </div>


                    {user.subscription.cancelled ? (

                        <span className="subscription-state cancelled-subscription">
                            <span className="material-symbols-outlined icon">cancel</span> Cancelled : {formatDate(user.subscription.end_date)}
                        </span>

                    ) : (

                        <span className="subscription-state active-subscription">
                            <span className="material-symbols-outlined icon">check</span> Next payment : {formatDate(user.subscription.end_date)}
                        </span>

                    )}

            </div>

            <span className='plan-name'>Pro plan</span>

            <button className='button-cancel cancel-sub' onClick={handleCancel}>
                <span className="material-symbols-outlined icon">cancel</span>
                Cancel subscription
            </button>
        </div>
        </div >
    );
};

export default User;
