import React, { createContext, useState, useEffect } from 'react';
import api from '../api';

// Crée le contexte d'authentification
export const AuthContext = createContext();

// Composant fournisseur pour AuthContext
export const AuthProvider = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {

            console.log("Token : " + localStorage.getItem('token'));

            setIsAuthenticated(true);

            api.get('/user_info')
            .then(response => {
                setUser(response.data);
                console.log("Get user : " + response.data.email);
            })
            .catch(error => console.error(error));
        }

    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};
