import { useEffect, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode } from '@lexical/rich-text'; // Importer HeadingNode
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import ToolbarPlugin from './ToolbarPlugin';
import AIToolbarPlugin from './AIToolbarPlugin';

import './Editor.css'

const theme = {
    // Ton thème ici
};

function onError(error) {
    console.error(error);
}

const EditorContentLoader = ({ initialContent }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // Fonction qui met à jour le contenu de l'éditeur avec le contenu JSON
        editor.update(() => {
            const editorState = editor.parseEditorState(initialContent);
            editor.setEditorState(editorState);
        });
    }, [editor, initialContent]);

    return null; // Pas de rendu, cette fonction se contente de charger le contenu
};

const Editor = ({ project, isNewProject }) => {

    const initialConfig = {
        namespace: 'MyEditor',
        theme,
        onError,
        nodes: [HeadingNode], // Enregistre HeadingNode dans l'éditeur
        formats: ['bold', 'italic', 'underline'], // Inclure underline ici
    };

    const [isLoading, setIsLoading] = useState(false); 

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className={`editor-container ${isLoading ? 'loading' : ''}`}>
                <EditorContentLoader initialContent={project.content} />
                <ToolbarPlugin projectId={project.id} isLoading={isLoading} />
                <RichTextPlugin className="text-editor"
                    contentEditable={<ContentEditable className={`content-editable ${isLoading ? 'disabled' : ''}`} />}
                    placeholder={<div>Enter some text...</div>}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <HistoryPlugin />
                <AutoFocusPlugin />
                <AIToolbarPlugin projectId={project.id} onLoading={setIsLoading} isNewProject={isNewProject} />
            </div>
        </LexicalComposer>
    );
}

export default Editor;
