import React, { useEffect, useState } from 'react';

import {
    $getSelection,
    $getRoot,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    UNDO_COMMAND,
    REDO_COMMAND,
    $createTextNode,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { mergeRegister } from '@lexical/utils';

import textCounter from '../../store/WordCount';

const ToolbarPlugin = ({ projectId, isLoading }) => {

    const [editor] = useLexicalComposerContext();
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderlined, setIsUnderlined] = useState(false);

    const [currentNodeType, setCurrentNodeType] = useState(null);
    const [showGenerateContent, setShowGenerateContent] = useState(false);

    const { setWordCount, setCharCount } = textCounter();

    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {

                const root = $getRoot();
                const textContent = root.getTextContent(); // Get entire text content
                const words = textContent.trim().split(/\s+/).length; // Word count
                const characters = textContent.length; // Character count
                setWordCount(words);
                setCharCount(characters);
            });
        });
    }, [editor, setWordCount, setCharCount]);


    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(() => {
                editor.getEditorState().read(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        // Détection du format (gras, italique, souligné)
                        setIsBold(selection.hasFormat('bold'));
                        setIsItalic(selection.hasFormat('italic'));
                        setIsUnderlined(selection.hasFormat('underline'));

                        // Parcours des parents pour trouver le nœud de type heading ou paragraphe
                        let node = selection.anchor.getNode();
                        let foundParagraph = false;
                        while (node !== null) {
                            if ($isHeadingNode(node)) {
                                setCurrentNodeType(node.getTag()); // h1, h2, h3

                                // Vérifier s'il y a un paragraphe juste après ce titre
                                let nextSibling = node.getNextSibling();
                                while (nextSibling !== null) {
                                    if (nextSibling.getType() === 'paragraph') {
                                        foundParagraph = true;
                                        break;
                                    }
                                    nextSibling = nextSibling.getNextSibling();
                                }

                                setShowGenerateContent(!foundParagraph); // Affiche Generate Content si pas de paragraphe
                                return;
                            } else if (node.getType() === 'paragraph') {
                                setCurrentNodeType('paragraph');
                                setShowGenerateContent(false);
                                return;
                            }
                            node = node.getParent(); // Remonter dans la hiérarchie des nœuds
                        }

                        setCurrentNodeType(null);
                        setShowGenerateContent(false);
                    }
                });
            })
        );
    }, [editor]);

    useEffect(() => {
        const handleScroll = () => {
            const toolbar = document.querySelector('.sticky-toolbar');
            if (toolbar) {
                if (window.scrollY > 100) {
                    toolbar.classList.add('scrolled');
                } else {
                    toolbar.classList.remove('scrolled');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Fonction pour ajouter une nouvelle section (h2)
    const addSection = () => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                const currentNode = selection.anchor.getNode();
                const newHeading = $createHeadingNode('h2');
                const textNode = $createTextNode('New Section');
                newHeading.append(textNode);
                currentNode.getTopLevelElementOrThrow().insertAfter(newHeading);
            }
        });
    };

    // Fonction pour ajouter un nouveau point (h3)
    const addPoint = () => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                const currentNode = selection.anchor.getNode();
                const newHeading = $createHeadingNode('h3');
                const textNode = $createTextNode('New Point');
                newHeading.append(textNode);
                currentNode.getTopLevelElementOrThrow().insertAfter(newHeading);
            }
        });
    };

    return (
        <div className="toolbar ">
            <button className="toolbar-icon-button" onClick={() => editor.dispatchCommand(UNDO_COMMAND)} aria-label="Undo" disabled={isLoading}>
                <span className="material-symbols-outlined icon">undo</span>
            </button>

            <button className="toolbar-icon-button" onClick={() => editor.dispatchCommand(REDO_COMMAND)} aria-label="Redo" disabled={isLoading}>
                <span className="material-symbols-outlined icon">redo</span>
            </button>

            <div class="v-hr"></div>

            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
                className={`toolbar-icon-button ${isBold ? 'active' : ''}`}
                aria-label="Bold"
                disabled={isLoading}
            >
                <span className="material-symbols-outlined icon">format_bold</span>
            </button>

            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
                className={`toolbar-icon-button ${isItalic ? 'active' : ''}`}
                aria-label="Italic"
                disabled={isLoading}
            >
                <span className="material-symbols-outlined icon">format_italic</span>
            </button>

            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
                className={`toolbar-icon-button ${isUnderlined ? 'active' : ''}`}
                aria-label="Underline"
                disabled={isLoading}
            >
                <span className="material-symbols-outlined icon">format_underlined</span>
            </button>

            <div class="v-hr"></div>

            <button className="toolbar-button" onClick={addSection} aria-label="Add Section (h2)" disabled={isLoading}>
                <span className="material-symbols-outlined icon">add</span>
                <span className="text">Add section</span>
            </button>

            <button className="toolbar-button" onClick={addPoint} aria-label="Add Point (h3)" disabled={isLoading}>
                <span className="material-symbols-outlined icon">add</span>
                <span className="text">Add point</span>
            </button>

            {isLoading && (
                <div class="generate-loading-container">
                    <span class="loading-text">Generating</span>
                    <div class="generate-loader"></div>
                </div>
            )}

        </div>
    );
};

export default ToolbarPlugin;
