import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContext';
import './Navbar.css'; // Assurez-vous d'inclure les styles
import Login from '../Login/Login'
import Signup from '../Login/Signup';
import { Link } from 'react-router-dom';
import User from '../Login/User';

const Navbar = () => {

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const { user, setUser } = useContext(AuthContext);

    const [loginDisplay, setLoginDisplay] = useState(false);
    const [signupDisplay, setSignupDisplay] = useState(false);
    const [userDisplay, setUserDisplay] = useState(false)

    const [showUserMenu, setShowUserMenu] = useState(false); // Gérer l'affichage du menu utilisateur

    const popupRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowUserMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handleUserMenuToggle = () => {
        setShowUserMenu((prevShowUserMenu) => !prevShowUserMenu);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds if it's in seconds
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    if (isAuthenticated && !user) {
        return <></>;
    }

    return (
        <>
            {loginDisplay && (
                <Login
                    onClose={setLoginDisplay}
                    onSignup={() => { setLoginDisplay(false); setSignupDisplay(true); }}
                />
            )}

            {signupDisplay && (
                <Signup
                    onClose={setSignupDisplay}
                    onLogin={() => { setLoginDisplay(true); setSignupDisplay(false); }}
                />
            )}

            {userDisplay && (
                <User
                    onClose={setUserDisplay}
                    onLogin={() => { setLoginDisplay(true); setUserDisplay(false); }}
                />
            )}

            <nav className="navbar">
                <div className="navbar-logo" onClick={() => { navigate("/") }} style={{ cursor: "pointer" }}>Scribus</div>
                <ul className="navbar-links">
                    <li><Link to="/pricing"> Pricing</Link></li>
                    <li><Link to="/support">Support</Link></li>
                    {/* <li>
                    <div className="language-selector">
                        En <span className="arrow-down">▼</span>
                    </div>
                </li> */}
                    <li>

                    </li>
                </ul>

                <div className='navbar-right'>
                    {!isAuthenticated ? (
                        <a onClick={() => { setLoginDisplay(true); }}>
                            Login
                        </a>
                    ) : (
                        <div className="user-menu">

                            <button className="user-icon" onClick={handleUserMenuToggle}>
                                <span className="material-symbols-outlined icon">person</span>
                            </button>

                            {showUserMenu && (
                                <div ref={popupRef} className="user-popup">
                                    <div className="user-popup-header">
                                        <strong>{user.subscription != null ? ('Pro') : ('Free')}</strong>
                                        <p>{user.email}</p>
                                    </div>
                                    <div className="user-popup-body">
                                        {user.subscription != null && user.subscription.active ? (

                                            user.subscription.cancelled ? (

                                                <span className="subscription-state cancelled-subscription" onClick={() => { setUserDisplay(true) }}>
                                                    <span className="material-symbols-outlined icon">cancel</span> Cancelled : {formatDate(user.subscription.end_date)}
                                                </span>

                                            ) : (

                                                <span className="subscription-state active-subscription" onClick={() => { setUserDisplay(true) }}>
                                                    <span className="material-symbols-outlined icon">check</span> Next payment : {formatDate(user.subscription.end_date)}
                                                </span>

                                            )

                                        ) : (
                                            <button onClick={() => { navigate("/pricing") }} className="button-plain">Upgrade to pro</button>
                                        )}

                                    </div>
                                    <div className="user-popup-footer">
                                        <button className="logout-button" onClick={() => {
                                            localStorage.removeItem('token');
                                            window.location.reload();
                                        }}><span className="material-symbols-outlined icon">logout</span>Logout</button>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                    {location.pathname !== '/app' && (
                        <button className="navbar-cta" onClick={() => { navigate('/app'); }}>Let's write</button>
                    )}

                </div>

                {/* Responsive menu button for mobile */}
                <div className="navbar-mobile-menu">
                    <input type="checkbox" className="navbar-checkbox" id="navbar-toggle" />
                    <label htmlFor="navbar-toggle" className="navbar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>

                    <div className="navbar-mobile-links">
                        <ul>
                            <li><Link to="/pricing"> Pricing</Link></li>
                            <li><Link to="/support">Support</Link></li>
                            {!isAuthenticated && (
                                <li onClick={() => { setLoginDisplay(true); }}>
                                    Login
                                </li>
                            )}
                            <li>
                                {location.pathname !== '/app' && (
                                    <button className="navbar-cta" onClick={() => { navigate('/app'); }}>Let's write</button>
                                )}
                            </li>
                        </ul>

                        {isAuthenticated && (
                            <div ref={popupRef} className="user-info-mobile">
                                <div className="">
                                    <strong>{user.subscription != null ? ('Pro') : ('Free')}</strong>
                                    <p>{user.email}</p>
                                </div>
                                <div className="">
                                    {user.subscription != null && user.subscription.active ? (

                                        user.subscription.cancelled ? (

                                            <span className="subscription-state cancelled-subscription" onClick={() => { setUserDisplay(true) }}>
                                                <span className="material-symbols-outlined icon">cancel</span> Cancelled : {formatDate(user.subscription.end_date)}
                                            </span>

                                        ) : (

                                            <span className="subscription-state active-subscription" onClick={() => { setUserDisplay(true) }}>
                                                <span className="material-symbols-outlined icon">check</span> Next payment : {formatDate(user.subscription.end_date)}
                                            </span>

                                        )

                                    ) : (
                                        <button onClick={() => { navigate("/pricing") }} className="button-plain">Upgrade to pro</button>
                                    )}

                                </div>
                                <div className="">
                                    <button className="logout-button" onClick={() => {
                                        localStorage.removeItem('token');
                                        window.location.reload();
                                    }}><span className="material-symbols-outlined icon">logout</span>Logout</button>
                                </div>
                            </div>
                        )}
                    </div>


                </div>
            </nav>
        </>
    );
};

export default Navbar;
