import React from 'react';
import { useState } from 'react';
import './LandingPage.css';
import Navbar from '../Navigation/Navbar';
import Typewriter from '../UIComponent/Typewriter';
import Login from '../Login/Login'
import Signup from '../Login/Signup';
import '../../styles/Underline.css'

const LandingPage = () => {

    const [openIndex, setOpenIndex] = useState(null);
    const [activeStyle, setActiveStyle] = useState('Formal');

    const [loginDisplay, setLoginDisplay] = useState(false);
    const [signupDisplay, setSignupDisplay] = useState(false);

    const texts = ["an essay", "an article", "a blog post", "an academic paper", "any kind of writing"];

    const stylePhrases = {
        Formal: 'Allow us to introduce Scribus.ai, an advanced AI-driven content creation platform that guarantees consistency in your brand’s messaging across all communication channels.',
        Informative: 'Scribus.ai showcases how artificial intelligence is transforming content creation across various industries, bringing innovative solutions.',
        Objective: 'Scribus.ai is a powerful tool designed to improve the precision and uniformity of your brand messaging efforts.',
        Analytical: 'Utilizing advanced AI techniques, Scribus.ai examines and generates content based on data-driven insights, ensuring optimal messaging.',
        Persuasive: 'Harness the potential of Scribus.ai, a solution crafted to elevate your brand\'s communication and deliver impactful messages.',
        Narrative: 'Once upon a time, Scribus.ai was developed to help brands craft their stories more effectively, ensuring each message resonates with its audience.',
        Descriptive: 'Scribus.ai is a state-of-the-art AI tool that generates engaging, personalized, and adaptable content tailored to your brand’s needs.',
        Casual: 'Scribus.ai makes content creation super simple, letting you focus on what really matters without the hassle.',
        Personal: 'With Scribus.ai, creating personalized, one-of-a-kind content has never been this effortless.'
    };

    const togglePanel = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleClick = (style) => {
        setActiveStyle(style);
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        localStorage.setItem('currentDescription', value);
    };

    return (
        <>
            <Navbar />

            {loginDisplay && (
                <Login
                    onClose={setLoginDisplay}
                    onSignup={() => { setLoginDisplay(false); setSignupDisplay(true); }}
                />
            )}

            {signupDisplay && (
                <Signup
                    onClose={setSignupDisplay}
                    onLogin={() => { setLoginDisplay(true); setSignupDisplay(false); }}
                />
            )}
            
            <div className="landing-page">
                <main>
                    <section>
                        <div className='container'>
                            <h1>
                                Elevate your writing game for <br />
                                <Typewriter texts={texts} typeSpeed={100} deleteSpeed={50} period={2000} />
                            </h1>
                            <p>Maximize your creativity, reduce writing time, and focus on what truly matters.</p>
                            <div className="input-box">
                                <textarea placeholder="Analyze the theme of revenge in Shakespeare’s Hamlet" onChange={handleDescriptionChange}/>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <button className="create-btn" onClick={() => { setLoginDisplay(true) }}>Get started for free</button>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className='container features'>
                            <h2>Express your <span className='underline'>uniqueness</span> every step of the way</h2>
                            <div className="features-grid">
                                <div className="feature">
                                    <h3>
                                        <span className="material-symbols-outlined icon">travel_explore</span>
                                        Topic Explorer
                                    </h3>
                                    <p>Unearth the best ideas for your writing through AI-powered suggestions.</p>
                                </div>
                                <div className="feature">
                                    <h3>
                                        <span className="material-symbols-outlined icon">format_list_bulleted</span>
                                        Outline Builder
                                    </h3>
                                    <p>Turn your thoughts into a structured outline with AI’s helping hand.</p>
                                </div>
                                <div className="feature">
                                    <h3>
                                        <span className="material-symbols-outlined icon">menu_book</span>
                                        Paper Assistant
                                    </h3>
                                    <p>Create complete academic papers with ease using our intelligent assistant.</p>
                                </div>
                                <div className="feature">
                                    <h3>
                                        <span className="material-symbols-outlined icon">edit_note</span>
                                        AI Co-Writer
                                    </h3>
                                    <p>Collaborate with AI to craft your text, offering insights and improvements along the way.</p>
                                </div>
                            </div>


                        </div>
                    </section>

                    <section>
                        <div className='container'>
                            <h2>
                                Write in the language <span class="underline">you speak</span>
                            </h2>
                            <p>Simply write in your preferred language and let Scribus handle the rest, delivering polished results.</p>
                        </div>
                    </section>

                    <section className="white-background">
                        <div className='container'>
                            <h2>Control the tone and style <span className='underline'>your way</span></h2>
                            <div className="style-buttons">
                                {Object.keys(stylePhrases).map((style) => (
                                    <button
                                        key={style}
                                        className={activeStyle === style ? 'active' : ''}
                                        onClick={() => handleClick(style)}
                                    >
                                        {style}
                                    </button>
                                ))}
                            </div>
                            <div className="style-result">
                                <p>{stylePhrases[activeStyle]}</p>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className='container'>
                            <h2>Frequently asked questions</h2>
                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(0)}>
                                    <span>Is it possible to maintain my personal writing style?</span>
                                    <span className="material-symbols-outlined icon">
                                        {openIndex === 0 ? 'expand_circle_up' : 'expand_circle_down'}
                                    </span>
                                </div>
                                {openIndex === 0 && (
                                    <div className="faq-answer">
                                        Absolutely! You can definitely retain your unique writing style while utilizing Aithor.com for your essay writing. Aithor.com offers flexibility throughout the entire writing process, allowing for adjustments whenever needed. Moreover, it includes features that help integrate your personal style, ensuring that your voice remains distinct in the final work.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(1)}>
                                    <span>Why choose Aithor.com over ChatGPT for writing an essay?</span>
                                    <span className="material-symbols-outlined icon">
                                        {openIndex === 1 ? 'expand_circle_up' : 'expand_circle_down'}
                                    </span>
                                </div>
                                {openIndex === 1 && (
                                    <div className="faq-answer">
                                        Aithor.com offers several advantages for essay writing compared to ChatGPT. Firstly, Aithor.com allows the creation of up to 10 pages of text, while ChatGPT typically limits output to only 2 pages. Secondly, Aithor.com includes a feature to mask the AI-generated content, making it appear more natural and personalized, unlike ChatGPT, which may be easily identified as AI text. Lastly, Aithor.com provides accurate references that adhere to citation styles such as MLA and APA.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(2)}>
                                    <span>What’s the process for writing an essay?</span>
                                    <span className="material-symbols-outlined icon">
                                        {openIndex === 2 ? 'expand_circle_up' : 'expand_circle_down'}
                                    </span>
                                </div>
                                {openIndex === 2 && (
                                    <div className="faq-answer">
                                        To write an essay, begin by researching your topic, organizing your thoughts, drafting your work, and then revising and editing. If you're pressed for time or need creative help, Scribus AI-powered service can assist you with starting your essay and enhancing your ideas.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(3)}>
                                    <span>What’s the purpose of an essay outline?</span>
                                    <span className="material-symbols-outlined icon">
                                        {openIndex === 3 ? 'expand_circle_up' : 'expand_circle_down'}
                                    </span>
                                </div>
                                {openIndex === 3 && (
                                    <div className="faq-answer">
                                        An essay outline is a structured framework that highlights the key points and supporting evidence in your essay. It helps keep your ideas organized and ensures a logical flow, making your essay easier to follow and more coherent.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(4)}>
                                    <span>Can AI assist in writing academic papers?</span>
                                    <span className="material-symbols-outlined icon">
                                        {openIndex === 4 ? 'expand_circle_up' : 'expand_circle_down'}
                                    </span>
                                </div>
                                {openIndex === 4 && (
                                    <div className="faq-answer">
                                        Yes, AI tools can assist you in writing academic papers and overcoming writer’s block. Aithor.com is one example of such an AI service, offering features that help with the research, planning, and reorganization of academic papers.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(5)}>
                                    <span>How can I lengthen my essay?</span>
                                    <span className="material-symbols-outlined icon">
                                        {openIndex === 5 ? 'expand_circle_up' : 'expand_circle_down'}
                                    </span>
                                </div>
                                {openIndex === 5 && (
                                    <div className="faq-answer">
                                        You can make your essay longer by providing more examples, expanding on arguments, and adding more detailed evidence. Alternatively, you can use Scribus AI service to help extend your essay by incorporating fresh ideas and additional content.
                                    </div>
                                )}
                            </div>

                            <div className="faq-item">
                                <div className="faq-question" onClick={() => togglePanel(6)}>
                                    <span>How do I write an ideal essay?</span>
                                    <span className="material-symbols-outlined icon">
                                        {openIndex === 6 ? 'expand_circle_up' : 'expand_circle_down'}
                                    </span>
                                </div>
                                {openIndex === 6 && (
                                    <div className="faq-answer">
                                        To craft a perfect essay, you should thoroughly research your topic, arrange your ideas logically, draft your essay, and then carefully revise and proofread. Scribus AI-powered service can also assist you in generating polished, high-quality content quickly and efficiently.
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>

                    <section className='white-background'>
                        <div className='container'>
                            <h2>Begin your writing journey with <span className='underline'>Scribus</span></h2>
                            <p>Get tailored papers delivered in no time.</p>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <button className="create-btn" onClick={() => { setLoginDisplay(true) }}>Start writing for free</button>
                            </div>
                            <div className="features-list">
                                <span>Expand, Shorten, and Rewrite</span>
                                <span>Custom Style & Tone</span>
                                <span>AI Detection & Masking</span>
                            </div>
                        </div>
                    </section>
                </main>

                <footer className="footer">
                    <div className="footer-container">
                        <div className="footer-column">
                            <h1>Scribus</h1>
                            <p>Your AI-powered writing companion: write smarter and faster.</p>
                        </div>

                        <div className="footer-column">
                            <h4>Resources</h4>
                            <ul>
                                {/* <li>Blog</li>
                                <li>Essay examples</li> */}
                                <li>Support</li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <a href="/terms">Terms of Service | Privacy & Cookie Policy</a>
                        <p>Copyright Scribus 2024</p>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default LandingPage;
