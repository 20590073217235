import React, { useState, useEffect, useRef } from 'react';
import './DropdownMenu.css'

const DropdownMenu = ({ isLoading, onSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  // Gérer l'ouverture/fermeture du menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const options = [
    "Formmaly",
    "Informatively",
    "Objectively",
    "Analytically",
    "Persuasively",
    "Narratively",
    "Descriptively",
    "Casually"
  ]

    // Fermer le menu si l'utilisateur clique en dehors du menu
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            closeMenu();
          }
        };
    
        if (isOpen) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside); // Nettoyage au démontage
        };
      }, [isOpen]);

  return (
    <div className="dropdown-container" ref={menuRef}>
      {/* Bouton principal "Paraphrase" */}
      <button
        className="toolbar-button"
        onClick={toggleMenu}
        aria-label="Expand"
        disabled={isLoading}
      >
        <span className="material-symbols-outlined icon">autorenew</span>
        <span className="text">Paraphrase</span>
      </button>

      {/* Menu déroulant */}
      {isOpen && (
        <div className="dropdown-menu">
            {options.map((option) => (
                <button onClick={() => {onSelected(option); closeMenu(); }} className="dropdown-item">{option}</button>
            ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
