import React, { useState } from 'react';

const ValidationPopup = ({ title, content, acceptText, denyText, onAccept, onDeny, onClose }) => {

    // Gérer le clic en dehors du popup pour le fermer
    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            onClose(false);
        }
    };

    return (
        <div className="overlay" onClick={handleOverlayClick}>
            <div className="popup">
                <h2>{title}</h2>
                <p>{content}</p>

                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'center' }}>
                <button className="button-cancel" onClick={onDeny}>
                    <span className="material-symbols-outlined icon">close</span>
                    {denyText}
                </button>

                <button className="button-plain" onClick={onAccept}>
                    <span className="material-symbols-outlined icon">check</span>
                    {acceptText}
                </button>
                </div>


            </div>
        </div>
    );
};

export default ValidationPopup;
