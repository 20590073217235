import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContext';
import api from '../api';
import './SideMenu.css';
import textCounter from '../../store/WordCount';
import ValidationPopup from '../UIComponent/ValidationPopup';
import ExportPopup from './ExportPopup';

const SideMenu = ({ onProjectSelected, onNewProject, onDelete, isNewProject, selectedProject }) => {

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const { user, setUser } = useContext(AuthContext);

    const [projects, setProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState(null);
    const [format, setFormat] = useState('docx');

    const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
    const [displayExportPopup, setDisplayExportPopup] = useState(false);
    const [deleteProjectData, setDeleteProjectData] = useState(null);
    const [userWordCount, setUserWordCount] = useState(0);

    const { wordCount, charCount } = textCounter();


    const navigate = useNavigate();

    const getUserWordCount = async () => {

        if (user) {
            setUserWordCount(user.word_count);
        }
        else {
            api.get('/user_info')
                .then(response => {
                    setUserWordCount(response.data.word_count);
                })
                .catch(error => console.error(error));
        }
    }

    const handleFormatToggle = (selectedFormat) => {
        setFormat(selectedFormat);
    };

    // Fonction pour récupérer la liste des projets
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await api.get(`/projects/user`);
                setProjects(response.data);

                if (selectedProject) {
                    setCurrentProject(selectedProject.id);
                }

                if (!isNewProject) {
                    if (response.data.length > 0) {

                        onProjectSelected(response.data[0].id);
                        setCurrentProject(response.data[0].id);
                    } else {
                        onNewProject();
                    }
                }

            } catch (error) {
                console.error('Erreur lors de la récupération des projets :', error);
            }
        };

        fetchProjects();
        getUserWordCount();
    }, []);

    const showProjectDeletePopup = (project) => {

        setDeleteProjectData(project);
        setDisplayDeletePopup(true);
    }

    const handleDeleteProject = async () => {

        try {
            await api.delete('/delete_project', {
                data: { project_id: deleteProjectData.id }
            });

            const updatedProjects = projects.filter(p => p.id !== deleteProjectData.id);
            setProjects(updatedProjects);

            setDisplayDeletePopup(false);

            if (updatedProjects.length > 0) {
                // If there are projects left, select the first one
                onProjectSelected(updatedProjects[0].id);
            } else {
                // If the list is empty, trigger the creation of a new project
                onNewProject();
            }
        } catch (error) {
            console.error('Erreur lors de la suppression du projet :', error);
        }
    }

    return (
        <>
            {displayExportPopup && (
                <ExportPopup 
                project={selectedProject}
                onClose={() => setDisplayExportPopup(false)} 
                />
            )}

            <div className="sidebar-menu">

                <div>
                    <button onClick={onNewProject} className="sidebar-button">
                        <span className="material-symbols-outlined icon">note_add</span>
                        <span className="text">Start new project</span>
                    </button>

                    <button className="sidebar-button">
                        <span className="material-symbols-outlined icon">description</span>
                        <span className="text">My documents</span>
                    </button>

                    <ul className="project-list">
                        {projects.map((project) => (
                            <li
                                key={project.id}
                                className={`project-item ${project.id == currentProject ? 'selected' : ''}`}
                                onClick={() => {
                                    onProjectSelected(project.id);
                                    setCurrentProject(project.id);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <span className="project-name">{project.name}</span>
                                <button
                                    className="delete-button"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Empêche de déclencher l'événement de sélection du projet
                                        showProjectDeletePopup(project); // Appelera la fonction pour supprimer
                                    }}
                                >
                                    <span className="material-symbols-outlined icon">close</span>
                                </button>
                            </li>
                        ))}
                    </ul>

                </div>

                {displayDeletePopup && (
                    <ValidationPopup
                        title="Delete project"
                        content={"Do you really want to delete the project " + deleteProjectData.name}
                        acceptText="Delete"
                        denyText="Cancel"
                        onDeny={() => { setDisplayDeletePopup(false) }}
                        onAccept={handleDeleteProject}
                        onClose={() => { setDisplayDeletePopup(false) }}
                    />
                )}

                <div>
                    <button onClick={() => { setDisplayExportPopup(true)/*onExport(currentProject)*/ }} className="sidebar-button">
                        <span className="material-symbols-outlined icon">upgrade</span>
                        <span className="text">Export</span>
                    </button>

                    {/* <button className="sidebar-button" onClick={() => {
                    localStorage.removeItem('token');
                    window.location.reload();
                }}>
                    <span className="material-symbols-outlined icon">logout</span>
                    <span className="text">Logout</span>
                </button> */}
                </div>

                {/* Bottom Panels */}
                <div className="bottom-panels">
                    <div className="bottom-panel">
                        <p className="token-info">Auto-disguise is used to prevent AI recognition. Plagiarism free.</p>
                    </div>

                    {user && (!user.subscription || !user.subscription.active) && (
                        <div className="bottom-panel">
                            <div className="token-stats" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: '500' }}>
                                <p>Free content</p>
                                <p>{userWordCount}/500</p>
                            </div>
                            <div className="token-progress">
                                <div className="token-progress-bar" style={{ width: `${Math.min(100, Math.max(0, (userWordCount / 500) * 100))}%` }}></div>
                            </div>

                        </div>
                    )}
                    <p style={{ textAlign: 'center', fontSize: '14px', fontWeight: '500' }}>{wordCount} words / {charCount} symbols</p>
                    <a style={{ display: "inline-block", marginTop: "15px" }} href="https://scribus.canny.io/" target='blank'>Give feedback</a>
                </div>
            </div>
        </>
    );
};

export default SideMenu;
