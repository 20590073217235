import React, { useState } from 'react';

import CreateProjectForm from './components/NewProject/CreateProjectForm';
//import Book from './components/Book';
import Editor from './components/Editor/Editor';
import SideMenu from './components/Editor/SideMenu';

import './App.css'
import './styles/Buttons.css'
import './styles/Popup.css'

import api from './components/api';
import Navbar from './components/Navigation/Navbar';
import WarningPopup from './components/UIComponent/WarningPopup';

const App = () => {

    const [currentPage, setCurrentPage] = useState('mainApp');
    const [currentProject, setCurrentProject] = useState(null);

    const [isNewProject, setIsNewProject] = useState(false);

    const handleNewProject = () => {

        setCurrentPage('newProject')
    };

    const handleProjectSelected = async (projectId) => {

        try {
            const response = await api.get(`/projects/${projectId}`);
            setCurrentProject(response.data);
            setCurrentPage('mainApp')
        } catch (error) {
            console.error('Project GET Error :', error);
        }
    };

    const handleProjectCreated = (projectId) => {

        setIsNewProject(true);
        handleProjectSelected(projectId);
    };

    return (
        <div>
            <Navbar />
            {currentPage === 'mainApp' && (
                <div className="main-layout">
                    <SideMenu
                        onProjectSelected={(projectId) => { setIsNewProject(false); handleProjectSelected(projectId); }}
                        onNewProject={handleNewProject}
                        isNewProject={isNewProject}
                        selectedProject={currentProject}
                    />
                    <div className='app-container'>
                        {currentProject ? (
                            <Editor project={currentProject} isNewProject={isNewProject} />
                        ) : (
                            <h1>Select a project</h1>
                        )}
                    </div>
                </div>
            )}

            {currentPage === 'newProject' && (
                // Afficher le formulaire de création de livre
                <CreateProjectForm onProjectCreated={handleProjectCreated} />
            )}

            <WarningPopup popupName="warningPopupDismissed">
                📱 While Scribus can be used on mobile, we highly recommend using it on a PC for a much smoother experience. Can Microsoft Word run on mobile? Yes. Is it a nightmare to use? Also, yes! 😅<br />
                <br />
                So, grab a coffee, settle in at your desk, and let the AI inspire your next essay. ☕️💻
            </WarningPopup>
        </div>

    );
};

export default App;
