import React, { useState } from 'react';
import api from '../api';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css'

const Signup = ({ onClose, onLogin }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/signup', { email: email });
            setMessage('Activation email sent to your email address');
        } catch (error) {
            console.error('Signup error:', error);
            setMessage('Error: Could not send activation email');
        }
    };

    // Gérer le clic en dehors du popup pour le fermer
    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            onClose(false);
        }
    };

    return (

        <div className="overlay" onClick={handleOverlayClick}>
            <div className="popup signup">
                <h2>Create an account</h2>
                <p>Add your email and create a password to create a new account</p>


                <form onSubmit={handleSignup}>
                    <div className="input-group">
                        <div className="icon-container">
                            <span className="material-symbols-outlined icon">mail</span>
                        </div>
                        <input
                            type="email"
                            placeholder="yours@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>



                    {/* <p className="separator-text">or connect with Google</p>
                        <div className="google-btn">
                            <img src="google-icon.png" alt="Google icon" />
                            <span>Sign in with Google</span>
                        </div> */}

                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="terms-checkbox"
                            required
                        />
                        <label htmlFor="terms-checkbox">
                            I agree without any reserve to the <a href="/terms">Terms and Conditions</a>
                        </label>
                    </div>

                    <button className='button-plain' type="submit">
                        <span className="material-symbols-outlined icon">mail</span>
                        Request activation email
                    </button>
                    <p>Already have an account? <a onClick={onLogin}>Login</a></p>
                </form>


                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default Signup;
