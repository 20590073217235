import { useEffect, useState } from 'react';
import api from '../api';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import TableToolbar from './TableToolbar';
import TableFiller from './TableFiller';
import TableValidation from './TableValidation';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HeadingNode, $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import {
    $getSelection,
    $setSelection,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    UNDO_COMMAND,
    REDO_COMMAND,
    $createTextNode,
    COMMAND_PRIORITY_EDITOR,
    KEY_TAB_COMMAND,
    KEY_ENTER_COMMAND,
    COMMAND_PRIORITY_HIGH ,
    $createRangeSelection,
    $createParagraphNode,
    $getRoot,
} from 'lexical';


function onError(error) {
    console.error(error);
}

const EnterBehaviour = () => {
    const [editor] = useLexicalComposerContext();

    const logKey = (commandName, priority) => {
        console.log(`Command: ${commandName}, Priority: ${priority}`);
      };

    useEffect(() => {
        // Une fois que l'éditeur est initialisé, on peut capturer l'événement "Enter"
        return editor.registerCommand(
            KEY_ENTER_COMMAND, // Capture la touche Enter
            (payload) => {
                console.log("ENTER");
                const event: KeyboardEvent = payload;
                event.preventDefault();
                editor.update(() => {
                    // Crée un nouvel HeadingNode de niveau H2
                    const selection = $getSelection();

                    if ($isRangeSelection(selection)) {
                        const currentNode = selection.anchor.getNode();
                        const newHeading = $createHeadingNode('h2');
                        const textNode = $createTextNode('');
                        newHeading.append(textNode);
                        currentNode.getTopLevelElementOrThrow().insertAfter(newHeading);

                        const newSelection = selection.clone();
                        newSelection.setTextNodeRange(textNode, textNode.getTextContentSize(), textNode, textNode.getTextContentSize());
                        $setSelection(newSelection);
                    }
                });

                return true; // Empêche le comportement par défaut
            },
            COMMAND_PRIORITY_HIGH 
        );
    }, [editor]);

    return null
}

const TableEditor = ({ project, onProjectValidation }) => {
    const initialConfig = {
        namespace: 'Table Editor',
        onError,
        nodes: [HeadingNode], // Enregistre HeadingNode dans l'éditeur
    };

    const [isLoading, setIsLoading] = useState(false); 

    const handleTableValidation = (projectId) => {
        onProjectValidation(projectId);
    }


    return (
        <div>
            <LexicalComposer initialConfig={initialConfig}>
                <div className={`editor-container table-editor ${isLoading ? 'loading' : ''}`}>
                    <TableFiller project={project} />
                    <TableToolbar onLoading={setIsLoading}/>
                    <EnterBehaviour />
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="content-editable"/>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <TableValidation projectId={project.project_id} onTableValidation={handleTableValidation} />
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                </div>
            </LexicalComposer>
            <></>
        </div>
    );
}

export default TableEditor;
