import React, { useEffect, useState } from 'react';
import {
    $getSelection,
    $setSelection,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    UNDO_COMMAND,
    REDO_COMMAND,
    $createTextNode,
    $createRangeSelection,
    $getRoot,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { mergeRegister } from '@lexical/utils';

import api from '../api';

const TableToolbar = ({onLoading}) => {

    const [editor] = useLexicalComposerContext();

    const [currentNodeType, setCurrentNodeType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loading = (loadingState) => {
        onLoading(loadingState);
        setIsLoading(loadingState);
    }

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(() => {
                editor.getEditorState().read(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {

                        // Parcours des parents pour trouver le nœud de type heading ou paragraphe
                        let node = selection.anchor.getNode();
                        while (node !== null) {
                            if ($isHeadingNode(node)) {
                                setCurrentNodeType(node.getTag()); // h1, h2, h3
                                return;
                            }
                            node = node.getParent(); // Remonter dans la hiérarchie des nœuds
                        }

                        // Si aucun nœud approprié n'est trouvé
                        setCurrentNodeType(null);
                    }
                });
            })
        );
    }, [editor]);

    useEffect(() => {
        const handleScroll = () => {
            const toolbar = document.querySelector('.sticky-toolbar');
            if (toolbar) {
                if (window.scrollY > 300) {
                    toolbar.classList.add('scrolled');
                } else {
                    toolbar.classList.remove('scrolled');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function selectAll(currentNode) {


        // Parcours des parents pour trouver le paragraphe
        let parentNode = currentNode;
        while (parentNode !== null && parentNode.getType() !== 'heading') {
            parentNode = parentNode.getParent();
        }

        // Si aucun parent paragraphe trouvé, sortir
        if (!parentNode) return;

        // Créer une nouvelle sélection qui couvre tout le contenu du paragraphe
        const rangeSelection = $createRangeSelection();
        const firstChild = parentNode.getFirstDescendant();
        const lastChild = parentNode.getLastDescendant();

        // Vérifier que les nœuds existent
        if (!firstChild || !lastChild) return;

        // Sélectionner tout le texte du parent (paragraphe)
        rangeSelection.setTextNodeRange(
            firstChild,
            0, // Début du premier nœud
            lastChild,
            lastChild.getTextContentSize() // Fin du dernier nœud
        );

        $setSelection(rangeSelection);

    }

    // Fonction pour ajouter une nouvelle section (h2)
    const addSection = () => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                const currentNode = selection.anchor.getNode();
                const newHeading = $createHeadingNode('h2');
                const textNode = $createTextNode('New Section');
                newHeading.append(textNode);
                currentNode.getTopLevelElementOrThrow().insertAfter(newHeading);
            }
        });
    };

    // Fonction pour ajouter un nouveau point (h3)
    const addPoint = () => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                const currentNode = selection.anchor.getNode();
                const newHeading = $createHeadingNode('h3');
                const textNode = $createTextNode('New Point');
                newHeading.append(textNode);
                currentNode.getTopLevelElementOrThrow().insertAfter(newHeading);
            }
        });
    };

    const handleRewrite = async () => {

        let selectedText;

        editor.update(() => {
            let selection = $getSelection();
            const currentNode = selection.anchor.getNode();

            selectAll(currentNode); 
            
            selection = $getSelection(); 
            if ($isRangeSelection(selection)) {
                selectedText = selection.getTextContent();
            }
        });

        if (!selectedText) {
            console.error('No content selected');
            return;
        }

        try {
            // Appel API pour étendre le contenu
            loading(true);
            const response = await api.post('/rewrite_title', { title: selectedText });
            const newTitle = response.data.new_title;

            // Faire la mise à jour dans un autre bloc update pour insérer le texte étendu
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    selection.insertText(newTitle);
                }
            });
        } catch (error) {
            console.error('Erreur lors de l\'extension du contenu :', error);
        } finally {
            loading(false);
        }
    };




    return (
        <div className="toolbar sticky-toolbar">

            <button className="toolbar-icon-button" onClick={() => editor.dispatchCommand(UNDO_COMMAND)} aria-label="Undo">
                <span className="material-symbols-outlined icon">undo</span>
            </button>

            <button className="toolbar-icon-button" onClick={() => editor.dispatchCommand(REDO_COMMAND)} aria-label="Redo">
                <span className="material-symbols-outlined icon">redo</span>
            </button>

            <div class="v-hr"></div>


            <button className="toolbar-button" onClick={addSection} aria-label="Add Section (h2)">
                <span className="material-symbols-outlined icon">add</span>
                <span className="text">Add section</span>
            </button>

            <button className="toolbar-button" onClick={addPoint} aria-label="Add Point (h3)">
                <span className="material-symbols-outlined icon">add</span>
                <span className="text">Add point</span>
            </button>

            {currentNodeType && (currentNodeType === 'h1' || currentNodeType === 'h2' || currentNodeType === 'h3') && (
                <button className="toolbar-button" onClick={handleRewrite} aria-label="Add Point (h3)">
                    <span className="material-symbols-outlined icon">edit</span>
                    <span className="text">Re-Write</span>
                </button>
            )}

        </div>
    );
};

export default TableToolbar;
