import React, { useState, useContext } from 'react';
import { AuthContext } from '../Login/AuthContext';
import './Pricing.css';
import api from '../api';
import Navbar from '../Navigation/Navbar';
import Login from '../Login/Login';
import Signup from '../Login/Signup';

const Pricing = () => {

    const [loginDisplay, setLoginDisplay] = useState(false);
    const [signupDisplay, setSignupDisplay] = useState(false);

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const { user, setUser } = useContext(AuthContext);

    const initiateCheckout = async (priceId) => {
        try {
            const response = await api.post(`/checkout/${priceId}`);
            window.location.href = response.data.checkout_url; // Rediriger vers Stripe Checkout
        } catch (error) {
            console.error('Error initiating checkout', error);
        }
    };

    return (
        <>
            <Navbar />

            {loginDisplay && (
                <Login
                    onClose={setLoginDisplay}
                    onSignup={() => { setLoginDisplay(false); setSignupDisplay(true); }}
                />
            )}

            {signupDisplay && (
                <Signup
                    onClose={setSignupDisplay}
                    onLogin={() => { setLoginDisplay(true); setSignupDisplay(false); }}
                />
            )}

            <div className="pricing pricing-container">
                <h1>Pricing</h1>
                <p className='subtitle' style={{marginTop: "30px"}}>Choose your subscription to get more benefits and savings of up to 67%</p>

                <div className="pricing-cards">
                    <div className="card free">
                        <h2>Free</h2>
                        <p>Enjoy unlimited access to all features with an annual subscription</p>
                        <ul>
                            <li className='v'>Table of contents generation and editing</li>
                            <li className='v'>Content generation and editing</li>
                            <li className='v'>Tone of voice selection</li>
                            <li className='x'>No queue</li>
                            <li className='x'>AI disguise</li>
                            <li className='x'>Early access to new features</li>
                        </ul>
                    </div>

                    <div className="card monthly">
                        <h2 className='pricing-name'>Monthly</h2>
                        <h3 className='price'>US$22.99 / month</h3>
                        <div className='pricing-details'>
                            <p className='monthly'>No discount</p>
                        </div>
                        {isAuthenticated ? (
                            <button onClick={() => { initiateCheckout(process.env.REACT_APP_MONTH_PRICE_ID) }}>Get it now</button>
                        ) : (
                            <button onClick={() => { setLoginDisplay(true) }}>Get it now</button>
                        )}
                        <ul>
                            <li className='v'>Table of contents generation and editing</li>
                            <li className='v'>Content generation and editing</li>
                            <li className='v'>Tone of voice selection</li>
                            <li className='v'>No queue</li>
                            <li className='v'>AI disguise</li>
                            <li className='v'>Early access to new features</li>
                        </ul>

                    </div>

                    <div className="card">
                        <h2 className='pricing-name'>Quarterly</h2>
                        <h3 className='price'>US$45.99 /3 months</h3>
                        <div className='pricing-details'>
                            <p className='monthly'>US$16.66/month</p>
                            <span className='saving'>33% off</span>
                        </div>
                        {isAuthenticated ? (
                            <button onClick={() => { initiateCheckout(process.env.REACT_APP_QUARTER_PRICE_ID) }}>Get it now</button>
                        ) : (
                            <button onClick={() => { setLoginDisplay(true) }}>Get it now</button>
                        )}
                        <ul>
                            <li className='v'>Table of contents generation and editing</li>
                            <li className='v'>Content generation and editing</li>
                            <li className='v'>Tone of voice selection</li>
                            <li className='v'>No queue</li>
                            <li className='v'>AI disguise</li>
                            <li className='v'>Early access to new features</li>
                        </ul>

                    </div>

                    <div className="card annualy">
                        <h2 className='pricing-name'>Annualy</h2>
                        <h3 className='price'>US$89.99 /year</h3>
                        <div className='pricing-details'>
                            <p className='monthly'>US$8.33/month</p>
                            <span className='saving'>67% off</span>
                        </div>
                        {isAuthenticated ? (
                            <button onClick={() => { initiateCheckout(process.env.REACT_APP_ANNUAL_PRICE_ID) }}>Get it now</button>
                        ) : (
                            <button onClick={() => { setLoginDisplay(true) }}>Get it now</button>
                        )}

                        <ul>
                            <li className='v'>Table of contents generation and editing</li>
                            <li className='v'>Content generation and editing</li>
                            <li className='v'>Tone of voice selection</li>
                            <li className='v'>No queue</li>
                            <li className='v'>AI disguise</li>
                            <li className='v'>Early access to new features</li>
                        </ul>

                    </div>
                </div>

                <p className="cancel-text">You can cancel your subscription at any time.</p>
            </div>
        </>
    );
};

export default Pricing;
