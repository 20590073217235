import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api';
import './Login.css'
import LoadingButton from '../UIComponent/LoadingButton';

const Login = ({ setAuth, onClose, onSignup }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailForLink, setEmailForLink] = useState('');
    const [loginOption, setLoginOption] = useState('email_link'); // 'email_password' or 'email_link'
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [isButtonLoading, setIsButtonLoading] = useState(false);


    // Gérer le login avec email et mot de passe
    const handleLoginWithPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/login', { email: email, password: password });
            localStorage.setItem('token', response.data.access_token);

            console.log("Reload Page");
            window.location.href = window.location.href;

        } catch (error) {
            console.error('Login error:', error);
            setMessage('Invalid email or password');
        }
    };

    // Gérer l'envoi du lien de login par email
    const handleLoginWithLink = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        try {
            const response = await api.post('/signup', { email: emailForLink });
            setMessage('Login link sent to your email');
        } catch (error) {
            console.error('Error sending login link:', error);
            setMessage('Failed to send login link');
        }
        finally {
            setIsButtonLoading(false);
        }
    };

    // Gérer le clic en dehors du popup pour le fermer
    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            onClose(false);
            setMessage(null);
        }
    };

    return (
        <div className="overlay" onClick={handleOverlayClick}>
            <div className="popup login">
                <h2>Login to your account</h2>
                <p style={{marginBottom:"10px"}}>Enter your email to recieve your one-click login link.</p>
                <i style={{display:"inline-block", fontSize:"14px", marginBottom:"30px", color:"#878787"}}>No worries! If your account doesn't exist, we'll create it automatically.</i>

                {loginOption === 'email_password' && (
                    <form onSubmit={handleLoginWithPassword}>
                        <div className="input-group">
                            <div className="icon-container">
                                <span className="material-symbols-outlined icon">mail</span>
                            </div>
                            <input
                                type="email"
                                placeholder="yours@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <div className="input-group">
                            <div className="icon-container">
                                <span className="material-symbols-outlined icon">lock</span>
                            </div>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <span
                                className="material-symbols-outlined eye-icon"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? 'visibility' : 'visibility_off'}
                            </span>
                        </div>

                        {/* <p className="separator-text">or connect with Google</p>
                        <div className="google-btn">
                            <img src="google-icon.png" alt="Google icon" />
                            <span>Sign in with Google</span>
                        </div> */}

                        <button className="button-plain" type="submit">Login</button>
                        <button className="button-hollow" onClick={() => setLoginOption('email_link')}>
                            <span className="material-symbols-outlined icon">mail</span>
                            Get one-time sign-in link
                        </button>
                        <p>New here? <a onClick={onSignup}>Create an account</a></p>
                    </form>
                )}

                {loginOption === 'email_link' && (
                    <form onSubmit={handleLoginWithLink}>

                        <div className="input-group">
                            <div className="icon-container">
                                <span className="material-symbols-outlined icon">mail</span>
                            </div>
                            <input
                                type="email"
                                placeholder="yours@example.com"
                                value={emailForLink}
                                onChange={(e) => setEmailForLink(e.target.value)}
                                required
                            />
                        </div>
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                id="terms-checkbox"
                                required
                            />
                            <label htmlFor="terms-checkbox">
                                I agree without any reserve to the <a href="/terms" target='blank'>Terms and Conditions</a>
                            </label>
                        </div>
                        <LoadingButton
                            isLoading={isButtonLoading}
                            disabled={isButtonLoading} // Le bouton est désactivé si l'email est vide
                        >
                            <span className="material-symbols-outlined icon">mail</span>
                            Send One-click login email
                        </LoadingButton>
                        {/* <button className="button-hollow" onClick={() => setLoginOption('email_password')}>
                        <span className="material-symbols-outlined icon">lock</span>
                            Login with email/password
                        </button> */}
                        {/* <p>New here? <a onClick={onSignup}>Create an account</a></p> */}
                    </form>
                )}


                {message && <div className='confirmation-message'><span className='material-symbols-outlined icon'>check</span><span>{message}</span></div>}
            </div>
        </div>
    );
};

export default Login;
