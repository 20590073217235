import React, { useState, useEffect } from 'react';
import api from '../api';

import TableEditor from './TableEditor';
import './CreateProjectForm.css';

const CreateProjectForm = ({ onProjectCreated }) => {
    const [currentStep, setCurrentStep] = useState('description');
    const [stepNumber, setStepNumber] = useState(1);
    const [newProject, setNewProject] = useState(null);

    const [pages, setPages] = useState(2);



    // State to store project data
    const [projectData, setProjectData] = useState({
        description: '',
        language: 'english',
        tone: 'Informative',
        length: pages
    });

    const MAX_CHARACTERS = 500; // Limite de caractères

    useEffect(() => {
        if (newProject) {
            setStepNumber(5); // Mettre à jour l'état en dehors du JSX
        }

        const desc = localStorage.getItem("currentDescription");
        if (desc){
            setProjectData({ ...projectData, ['description']: desc });
        }

    }, [newProject]);

    // Handle step change
    const nextStep = (stepNumber) => {
        setCurrentStep(stepsName[stepNumber]);
        setStepNumber(stepNumber);
    };

    const handleSliderChange = (event) => {
        setPages(event.target.value);
        setProjectData({ ...projectData, ['length']: event.target.value });
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectData({ ...projectData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        nextStep(5);

        // Use project data for the API request
        api.post('/create_project', {
            description: projectData.description,
            language: projectData.language,
            tone: projectData.tone,
            length: projectData.length
        })
            .then(response => {
                const newProject = response.data;
                setNewProject(newProject);
            })
            .catch(error => console.error(error));
    };

    // Handle project validation in TableEditor
    const handleProjectValidation = (projectId) => {
        onProjectCreated(projectId);
    };

    // List of languages with flags and lowercased names
    const languages = [
        { name: 'arabic', flag: '🇸🇦' },
        { name: 'bengali', flag: '🇧🇩' },
        { name: 'chinese', flag: '🇨🇳' },
        { name: 'dutch', flag: '🇳🇱' },
        { name: 'english', flag: '🇬🇧' }, // English selected by default
        { name: 'french', flag: '🇫🇷' },
        { name: 'german', flag: '🇩🇪' },
        { name: 'greek', flag: '🇬🇷' },
        { name: 'hindi', flag: '🇮🇳' },
        { name: 'italian', flag: '🇮🇹' },
        { name: 'japanese', flag: '🇯🇵' },
        { name: 'korean', flag: '🇰🇷' },
        { name: 'norwegian', flag: '🇳🇴' },
        { name: 'polish', flag: '🇵🇱' },
        { name: 'portuguese', flag: '🇵🇹' },
        { name: 'russian', flag: '🇷🇺' },
        { name: 'spanish', flag: '🇪🇸' },
        { name: 'swedish', flag: '🇸🇪' },
        { name: 'turkish', flag: '🇹🇷' },
    ];

    const lengthEnum = {
        1: "short",
        2: "medium",
        3: "long",
        4: "very_long",
    }

    const stepsName = {
        1: "description",
        2: "language",
        3: "length",
        4: "tone",
        5: "toc"
    }

    const stepsTitle = {
        1: "What do you want us to write about?",
        2: "Which language do you want us to write in?",
        3: "How long should it be?",
        4: "How should it sound? Pick your tone of voice",
        5: "Review & edit your table of contents",
    };


    return (
        <div className='create-project-container'>



            <h1>{stepsTitle[stepNumber]}</h1>
            <div class="step-container">
                <div class={stepNumber == 1 ? "step active" : "step"}>
                    <span class="step-number">1</span>
                </div>
                <div class={stepNumber == 2 ? "step active" : "step"}>
                    <span class="step-number">2</span>
                </div>
                <div class={stepNumber == 3 ? "step active" : "step"}>
                    <span class="step-number">3</span>
                </div>
                <div class={stepNumber == 4 ? "step active" : "step"}>
                    <span class="step-number">4</span>
                </div>
                <div class={stepNumber == 5 ? "step active" : "step"}>
                    <span class="step-number">5</span>
                </div>
                {/* <div class={stepNumber == 6 ? "step active" : "step"}>
                            <span class="step-number">6</span>
                        </div> */}
            </div>
            {newProject ? (
                // If the project is created, show the table editor
                <TableEditor project={newProject} onProjectValidation={handleProjectValidation} />
            ) : (
                <div>



                    {/* Step for project description */}
                    {currentStep === 'description' && (
                        <div className="creation-step">
                            <textarea
                                name="description"
                                value={projectData.description}
                                onChange={handleChange}
                                placeholder="Enter project description / topic / subject"
                                maxLength={MAX_CHARACTERS}
                                required
                            />
                            <div className="char-counter">
                                {projectData.description.length} / {MAX_CHARACTERS}
                            </div>
                        </div>
                        
                    )}

                    {/* Step for selecting language */}
                    {currentStep === 'language' && (
                        <div className="creation-step">
                            <select
                                name="language"
                                value={projectData.language}
                                onChange={handleChange}
                                required
                            >
                                {languages.map((lang) => (
                                    <option key={lang.name} value={lang.name} selected={lang.name === "english"}>
                                        {lang.name.charAt(0).toUpperCase() + lang.name.slice(1)} {lang.flag}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {currentStep === 'length' && (
                        <div className="creation-step">
                            <input
                                type="range"
                                min="1"
                                max="6"
                                value={pages}
                                onChange={handleSliderChange}
                                className="slider"
                            />
                            <p>Approx. {pages*2 * 7200} - {pages*2 * 7200 * 2} symbols / {pages*2 * 1200} - {pages*2 * 1200*2} words</p>


                        </div>
                    )}

                    {/* Step for selecting tone */}
                    {currentStep === 'tone' && (
                        <div className="creation-step">
                            <div className="tone-options">
                                {['Formal', 'Informative', 'Objective', 'Analytical', 'Persuasive', 'Narrative', 'Descriptive', 'Casual'].map((tone) => (
                                    <button
                                        type="button"
                                        key={tone}
                                        className={`tone-option ${projectData.tone === tone ? 'selected' : ''}`}
                                        onClick={() => setProjectData({ ...projectData, tone })}
                                    >
                                        {tone}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}

                    {currentStep === "toc" && (

                        <svg xmlns="http://www.w3.org/2000/svg" height="200px" width="200px" viewBox="0 0 200 200" class="pencil">
                            <defs>
                                <clipPath id="pencil-eraser">
                                    <rect height="30" width="30" ry="5" rx="5"></rect>
                                </clipPath>
                            </defs>
                            <circle transform="rotate(-113,100,100)" stroke-linecap="round" stroke-dashoffset="439.82" stroke-dasharray="439.82 439.82" stroke-width="2" stroke="#00bfff" fill="none" r="70" class="pencil__stroke"></circle>
                            <g transform="translate(100,100)" class="pencil__rotate">
                                <g fill="none">
                                    <circle transform="rotate(-90)" stroke-dashoffset="402" stroke-dasharray="402.12 402.12" stroke-width="30" stroke="hsl(195, 100%, 50%)" r="64" class="pencil__body1"></circle>
                                    <circle transform="rotate(-90)" stroke-dashoffset="465" stroke-dasharray="464.96 464.96" stroke-width="10" stroke="hsl(195, 100%, 60%)" r="74" class="pencil__body2"></circle>
                                    <circle transform="rotate(-90)" stroke-dashoffset="339" stroke-dasharray="339.29 339.29" stroke-width="10" stroke="hsl(195, 100%, 40%)" r="54" class="pencil__body3"></circle>
                                </g>
                                <g transform="rotate(-90) translate(49,0)" class="pencil__eraser">
                                    <g class="pencil__eraser-skew">
                                        <rect height="30" width="30" ry="5" rx="5" fill="hsl(195, 100%, 70%)"></rect>
                                        <rect clip-path="url(#pencil-eraser)" height="30" width="5" fill="hsl(195, 100%, 60%)"></rect>
                                        <rect height="20" width="30" fill="hsl(195, 10%, 90%)"></rect>
                                        <rect height="20" width="15" fill="hsl(195, 10%, 70%)"></rect>
                                        <rect height="20" width="5" fill="hsl(195, 10%, 80%)"></rect>
                                        <rect height="2" width="30" y="6" fill="hsla(195, 10%, 10%, 0.2)"></rect>
                                        <rect height="2" width="30" y="13" fill="hsla(195, 10%, 10%, 0.2)"></rect>
                                    </g>
                                </g>
                                <g transform="rotate(-90) translate(49,-30)" class="pencil__point">
                                    <polygon points="15 0,30 30,0 30" fill="hsl(33, 100%, 70%)"></polygon>
                                    <polygon points="15 0,6 30,0 30" fill="hsl(33, 100%, 50%)"></polygon>
                                    <polygon points="15 0,20 10,10 10" fill="hsl(195, 10%, 10%)"></polygon>
                                </g>
                            </g>
                        </svg>

                    )}
                </div>
            )}

            <div className='navigation-buttons'>
                {stepNumber > 1 && stepNumber < 5 && (
                    <button className="button-cancel" onClick={() => nextStep(stepNumber - 1)}>
                        <span class="material-symbols-outlined">arrow_back_ios</span>
                        Back
                    </button>
                )}

                {stepNumber < 4 && (
                    <button className="button-plain" onClick={() => nextStep(stepNumber + 1)} disabled={!projectData.description.trim()}>
                        Next
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                    </button>
                )}

                {stepNumber == 4 && (
                    <button className="button-plain" onClick={handleSubmit}>
                        Create table of content
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                    </button>
                )}


            </div>
        </div>
    );
};

export default CreateProjectForm;
