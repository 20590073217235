import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContext';
import api from '../api';
import DropdownMenu from '../UIComponent/DropdownMenu';
import './AIToolbarPlugin.css'

import {
    $getRoot,
    $getSelection,
    $setSelection,
    $isRangeSelection,
    $createTextNode,
    $createRangeSelection,
    $createParagraphNode
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { mergeRegister } from '@lexical/utils';

// Fonction de debounce
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const AIToolbarPlugin = ({ projectId, onLoading, isNewProject }) => {

    const navigate = useNavigate();

    const [isAlreadyGenerated, setIsAlreadyGenerated] = useState(false);

    const [editor] = useLexicalComposerContext();
    const [savedSelection, setSavedSelection] = useState(null);

    const [currentNodeType, setCurrentNodeType] = useState(null);
    const [promptTextareaValue, setPromptTextareaValue] = useState('');
    const [personalizeText, setPersonalizeText] = useState(() => {
        if (localStorage.getItem("personalizeText")) {
            return localStorage.getItem("personalizeText")
        }
        else {
            return "";
        }
    });

    const [showGenerateContent, setShowGenerateContent] = useState(false);
    const [displayCustomPromptPopup, setDisplayCustomPromptPopup] = useState(false);
    const [displayPersonalizePopup, setDisplayPersonalizePopup] = useState(false);

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const { user, setUser } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            setDisplayCustomPromptPopup(false);
        }
    };

    useEffect(() => {
        if (isNewProject && !isAlreadyGenerated) {
            const timer = setTimeout(() => {
                setIsAlreadyGenerated(true);
                handleGenerateContentForAllChapters();
            }, 1000); // 1000 ms = 1 seconde

            // Nettoyer le timer si le composant est démonté ou si isNewProject change
            return () => clearTimeout(timer);
        }
    }, [isNewProject]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(() => {
                editor.getEditorState().read(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {

                        // Parcours des parents pour trouver le nœud de type heading ou paragraphe
                        let node = selection.anchor.getNode();
                        while (node !== null) {
                            if ($isHeadingNode(node)) {
                                setCurrentNodeType(node.getTag()); // h1, h2, h3

                                // Vérifier uniquement le nœud juste après ce heading
                                let nextSibling = node.getNextSibling();

                                // Si le nœud suivant n'existe pas ou n'est pas un paragraphe, on affiche "Generate Content"
                                if (nextSibling === null || nextSibling.getType() !== 'paragraph') {
                                    setShowGenerateContent(true);
                                } else {
                                    setShowGenerateContent(false);
                                }
                                return;
                            } else if (node.getType() === 'paragraph') {
                                setCurrentNodeType('paragraph');
                                setShowGenerateContent(false);
                                return;
                            }
                            node = node.getParent(); // Remonter dans la hiérarchie des nœuds
                        }

                        setCurrentNodeType(null);
                        setShowGenerateContent(false);
                    }
                });
            })
        );
    }, [editor]);

    const updateUser = async () => {
        api.get('/user_info')
            .then(response => {
                setUser(response.data);
            })
            .catch(error => console.error(error));
    }

    const loading = (loadingState) => {
        onLoading(loadingState);
        setIsLoading(loadingState);
    }

    const saveContent = () => {
        editor.update(() => {
            const editorState = editor.getEditorState();
            const content = JSON.stringify(editorState.toJSON());

            api.post('/update_content', {
                projectId: projectId,
                content: content
            })
                .then((response) => {
                    console.log(response.data.message);
                })
                .catch((error) => {
                    console.error('Content save error :', error);
                });
        });
    };

    const saveContentDebounced = useCallback(debounce(() => {
        console.log("SAVE CONTENT DEBOUNCE");
        saveContent();
        updateUser();
    }, 5000), [saveContent]);

    useEffect(() => {
        const unregister = editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                // Appeler la sauvegarde après une modification

                saveContentDebounced();  // Déclencher la sauvegarde via debounce
            });
        });

        return () => unregister();
    }, [editor, saveContentDebounced]);

    const openCustomPromptPopup = () => {
        editor.update(() => {
            const selection = $getSelection();
            if (selection !== null) {
                // Sauvegarde de la sélection
                setSavedSelection(selection.clone());
            }
        });

        setDisplayCustomPromptPopup(true);
    }


    function selectAll(currentNode) {

        // Parcours des parents pour trouver le paragraphe
        let parentNode = currentNode;
        while (parentNode !== null && parentNode.getType() !== 'paragraph' && parentNode.getType() !== 'heading') {

            parentNode = parentNode.getParent();

        }

        // Si aucun parent paragraphe trouvé, sortir
        if (!parentNode) return;

        // Créer une nouvelle sélection qui couvre tout le contenu du paragraphe
        const rangeSelection = $createRangeSelection();
        const firstChild = parentNode.getFirstDescendant();
        const lastChild = parentNode.getLastDescendant();

        // Vérifier que les nœuds existent
        if (!firstChild || !lastChild) return;

        // Sélectionner tout le texte du parent (paragraphe)
        rangeSelection.setTextNodeRange(
            firstChild,
            0, // Début du premier nœud
            lastChild,
            lastChild.getTextContentSize() // Fin du dernier nœud
        );

        $setSelection(rangeSelection);

    }

    const getOrSelectText = () => {

        let contentToModify;

        // Mettre à jour et sélectionner tout le texte si nécessaire
        editor.update(() => {
            let selection = $getSelection();
            const currentNode = selection.anchor.getNode();

            // Si aucune partie du texte n'est sélectionnée, sélectionner tout le paragraphe
            if (!selection || selection.anchor.offset === selection.focus.offset) {
                selectAll(currentNode); // Sélectionner tout le paragraphe
            }

            // Une fois la sélection mise à jour, lire le texte
            selection = $getSelection(); // Obtenir la nouvelle sélection
            if ($isRangeSelection(selection)) {
                contentToModify = selection.getTextContent(); // Texte complet sélectionné
            }
        });

        return contentToModify
    }

    const handleRewrite = async () => {

        let selectedText;

        editor.update(() => {
            let selection = $getSelection();
            const currentNode = selection.anchor.getNode();

            selectAll(currentNode);

            selection = $getSelection();
            if ($isRangeSelection(selection)) {
                selectedText = selection.getTextContent();
            }
        });

        if (!selectedText) {
            console.error('No content selected');
            return;
        }

        try {
            // Appel API pour étendre le contenu
            loading(true);
            const response = await api.post('/rewrite_title', { title: selectedText });
            const newTitle = response.data.new_title;

            // Faire la mise à jour dans un autre bloc update pour insérer le texte étendu
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    selection.insertText(newTitle);
                    saveContent();
                }
            });
        } catch (error) {
            console.error('Erreur lors de l\'extension du contenu :', error);
        } finally {
            loading(false);
        }
    };

    const handleGenerateContent = async () => {
        let chapterTitle;
        editor.update(() => {
            const selection = $getSelection();
            let currentNode = selection.anchor.getNode();

            while (currentNode !== null) {
                if ($isHeadingNode(currentNode)) {
                    chapterTitle = currentNode.getTextContent(); // Récupère le texte du titre
                    console.log("Generate content for chapter : " + chapterTitle);
                }

                currentNode = currentNode.getParent(); // Remonter dans la hiérarchie des nœuds
            }
        });

        if (!chapterTitle) {
            console.error('Aucun titre disponible');
            return;
        }

        try {

            loading(true);
            const response = await api.post('/generate_content', {
                chapter_title: chapterTitle,
                project_id: projectId
            });
            const generatedContent = response.data.generated_content;

            // Insérer un nouveau paragraphe juste après le titre
            editor.update(() => {
                const selection = $getSelection();
                let currentNode = selection.anchor.getNode();

                while (currentNode !== null) {

                    if ($isHeadingNode(currentNode)) {
                        const newParagraph = $createTextNode(generatedContent);
                        const paragraphNode = $createParagraphNode(); // Crée un nouveau paragraphe
                        paragraphNode.append(newParagraph);
                        currentNode.insertAfter(paragraphNode); // Insère après le titre
                    }

                    currentNode = currentNode.getParent(); // Remonter dans la hiérarchie des nœuds
                }


            });

            setTimeout(() => {
                saveContent(); // Sauvegarder le contenu après un petit délai
            }, 1000); // 100ms de délai devrait être suffisant

        } catch (error) {
            console.error('Erreur lors de la génération du contenu :', error);
        } finally {
            // Fin du chargement
            loading(false);
        }
    };



    const handleExpandContent = async () => {
        let selectedText = getOrSelectText();

        if (!selectedText) {
            console.error('No content selected');
            return;
        }

        try {
            // Appel API pour étendre le contenu
            loading(true);
            const response = await api.post('/expand_content', { content: selectedText });
            const expandedContent = response.data.modified_content;

            // Faire la mise à jour dans un autre bloc update pour insérer le texte étendu
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    selection.insertText(expandedContent); // Replace selection
                    saveContent();
                }
            });
        } catch (error) {
            console.error('Erreur lors de l\'extension du contenu :', error);
        } finally {
            loading(false);
        }
    };


    const handleShortenContent = async () => {
        let selectedText = getOrSelectText();

        if (!selectedText) {
            console.error('No content selected');
            return;
        }

        try {
            // Appel API pour étendre le contenu
            loading(true);
            const response = await api.post('/shorten_content', { content: selectedText });
            const ShortenedContent = response.data.modified_content;

            // Faire la mise à jour dans un autre bloc update pour insérer le texte étendu
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    selection.insertText(ShortenedContent);
                    saveContent();
                }
            });
        } catch (error) {
            console.error('Erreur lors de l\'extension du contenu :', error);
        } finally {
            loading(false);
        }
    };



    const handleParaphrase = async (tone) => {

        let selectedText = getOrSelectText();

        if (!selectedText) {
            console.error('Aucun contenu à étendre');
            return;
        }

        try {
            loading(true);
            const response = await api.post('/paraphrase_content', { content: selectedText, tone: tone });
            const newContent = response.data.modified_content;

            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    selection.insertText(newContent);
                    saveContent();
                }
            });
        } catch (error) {
            console.error('Paraphrase Error : ', error);
        } finally {
            loading(false);
        }
    }

    const handleCustomize = async () => {

        setDisplayCustomPromptPopup(false);

        editor.update(() => {
            if (savedSelection !== null) {
                $setSelection(savedSelection);
            }
        });

        let selectedText = getOrSelectText();

        if (!selectedText) {
            console.error('No content selected');
            return;
        }

        try {
            loading(true);
            const response = await api.post('/prompt_content', { content: selectedText, prompt: promptTextareaValue });

            const newContent = response.data.modified_content;

            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    selection.insertText(newContent);
                    saveContent();
                }
            });
        } catch (error) {
            console.error('Paraphrase Error : ', error);
        } finally {
            loading(false);
        }
    }

    const handleSetPersonalizeText = () => {
        if (personalizeText != "") {
            localStorage.setItem("personalizeText", personalizeText);
            handlePersonalize();
        }
    }

    const handlePersonalize = async () => {

        if (!localStorage.getItem("personalizeText")) {
            setDisplayPersonalizePopup(true);
            return;
        }
        else {

            setDisplayPersonalizePopup(false);

            editor.update(() => {
                if (savedSelection !== null) {
                    $setSelection(savedSelection);
                }
            });

            let selectedText = getOrSelectText();

            if (!selectedText) {
                console.error('No content selected');
                return;
            }

            try {
                loading(true);
                const response = await api.post('/personalize', { content: selectedText, exemple: localStorage.getItem("personalizeText") });

                const newContent = response.data.modified_content;

                editor.update(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        selection.insertText(newContent);
                        saveContent();
                    }
                });
            } catch (error) {
                console.error('Personalize Error : ', error);
            } finally {
                loading(false);
            }
        }
    }

    const handleGenerateContentForAllChapters = async () => {

        let h3Titles = [];

        editor.getEditorState().read(() => {
            const root = $getRoot(); // Récupérer la racine de l'éditeur

            // Parcourir tous les enfants pour trouver les titres de type h2
            root.getChildren().forEach((node) => {
                if ($isHeadingNode(node) && node.getTag() === 'h3') {
                    h3Titles.push({
                        title: node.getTextContent(),
                        node: node, // Sauvegarder le nœud pour insérer du contenu après
                    });
                }
            });

            return h3Titles;
        });

        for (const { title, node } of h3Titles) {
            try {
                loading(true); // Démarre le chargement

                const response = await api.post('/generate_content', {
                    chapter_title: title,
                    project_id: projectId,
                    draft: true,
                });

                const generatedContent = response.data.generated_content;

                // Insérer un nouveau paragraphe juste après le titre
                editor.update(() => {

                    const newParagraph = $createTextNode(generatedContent);
                    const paragraphNode = $createParagraphNode(); // Crée un nouveau paragraphe
                    paragraphNode.append(newParagraph);
                    node.insertAfter(paragraphNode); // Insérer après le titre

                });

                // Sauvegarder le contenu
                setTimeout(() => {
                    saveContent(); // Sauvegarder le contenu après un délai
                }, 100); // 100ms de délai devrait être suffisant

            } catch (error) {
                console.error('Erreur lors de la génération du contenu :', error);
            } finally {
                loading(false); // Fin du chargement
            }
        }
    };

    return (
        <>
            {(user && (!user.subscription || !user.subscription.active) && user.word_count > 500) ? (
                <div className='free-limit-panel'>
                    <h2>You’ve hit your free limit</h2>
                    <p>This essay has reached its allocation of complimentary actions. Upgrade for AI-guided topics, trusted references, and content disguise tools.</p>
                    <button className='button-plain' onClick={() => { navigate('/pricing'); }}>Upgrade</button>
                </div>
            ) : (

                <div className="ai-toolbar">

                    {/* <button className="toolbar-button" onClick={handleGenerateContentForAllChapters} aria-label="Expand" disabled={isLoading}>
                    <span className="material-symbols-outlined icon">code</span>
                    <span className="text">DEBUG</span>
                </button> */}

                    {currentNodeType && (currentNodeType === 'h1' || currentNodeType === 'h2' || currentNodeType === 'h3') && (
                        <>
                            {showGenerateContent && (
                                <button className="toolbar-button" onClick={handleGenerateContent} aria-label="Generate Content" disabled={isLoading}>
                                    <span className="material-symbols-outlined icon">award_star</span>
                                    <span className="text">Generate Content</span>
                                </button>
                            )}

                            <button className="toolbar-button" onClick={handleRewrite} aria-label="Rewrite Title" disabled={isLoading}>
                                <span className="material-symbols-outlined icon">edit</span>
                                <span className="text">Re-Write</span>
                            </button>
                        </>
                    )}

                    {currentNodeType === 'paragraph' && (
                        <>
                            <button className="toolbar-button" onClick={() => { setDisplayCustomPromptPopup(true) }} aria-label="Expand" disabled={isLoading}>
                                <span className="material-symbols-outlined icon">draw</span>
                                <span className="text">Customize</span>
                            </button>

                            {/* <button className="toolbar-button" onClick={""} aria-label="Expand" disabled={isLoading}>
                            <span className="material-symbols-outlined icon">format_quote</span>
                            <span className="text">Find references</span>
                        </button> */}

                            <button className="toolbar-button" onClick={() => { setDisplayPersonalizePopup(true) }} aria-label="Expand" disabled={isLoading}>
                                <span className="material-symbols-outlined icon">edit_note</span>
                                <span className="text">Personalize</span>
                            </button>

                            <DropdownMenu isLoading={isLoading} onSelected={handleParaphrase} />

                            <button className="toolbar-button" onClick={handleExpandContent} aria-label="Expand" disabled={isLoading}>
                                <span className="material-symbols-outlined icon">expand</span>
                                <span className="text">Expand</span>
                            </button>

                            <button className="toolbar-button" onClick={handleShortenContent} aria-label="Shorten" disabled={isLoading}>
                                <span className="material-symbols-outlined icon">compress</span>
                                <span className="text">Shorten</span>
                            </button>
                        </>
                    )}
                </div>
            )}

            {displayCustomPromptPopup && (
                <div className="overlay" onClick={handleOverlayClick}>
                    <div className="customize-popup popup">
                        <p>Special instructions for rewriting this text</p>

                        <textarea className='prompt-textarea'
                            placeholder="e.g. Provide more historical information about this topic"
                            onChange={(e) => setPromptTextareaValue(e.target.value)}></textarea>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'center' }}>

                            <button className="button-cancel" onClick={() => { setDisplayCustomPromptPopup(false) }}>
                                <span className="material-symbols-outlined icon">close</span>
                                Cancel
                            </button>

                            <button className="button-plain" onClick={handleCustomize}>
                                <span className="material-symbols-outlined icon">check</span>
                                Customize
                            </button>

                        </div>

                    </div>
                </div>
            )}

            {displayPersonalizePopup && (
                <div className="overlay" onClick={handleOverlayClick}>
                    <div className="customize-popup popup">
                        <p>AI needs a text sample of sufficient length (500-2000 characters) to replicate your writing style. It could be from a past essay, paper, or letter – the subject isn't important.</p>

                        <textarea className='prompt-textarea'
                            placeholder="e.g, Provide your text sample"
                            value={personalizeText}
                            onChange={(e) => { setPersonalizeText(e.target.value); }}></textarea>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'center' }}>

                            <button className="button-cancel" onClick={() => { setDisplayPersonalizePopup(false) }}>
                                <span className="material-symbols-outlined icon">close</span>
                                Cancel
                            </button>

                            <button className="button-plain" onClick={handleSetPersonalizeText}>
                                <span className="material-symbols-outlined icon">check</span>
                                Personalize
                            </button>

                        </div>

                    </div>
                </div>
            )}

        </>
    );
};

export default AIToolbarPlugin;
