import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './components/Login/AuthContext';
import App from './App';
import LoginWithToken from './components/Login/LoginWithToken';
import LandingPage from './components/Main/LandingPage';
import Pricing from './components/Main/Pricing';
import Support from './components/Main/Support';
import TermsAndPrivacy from './components/Main/TermsAndPrivacy';

const AppRouter = () => {

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    return (
        <Router>
            <Routes>
                <Route 
                    path="/" 
                    element={isAuthenticated ? <Navigate to="/app" /> : <LandingPage/>} 
                />
                <Route 
                    path="/login_with_token" 
                    element={isAuthenticated ? <Navigate to="/app" /> : <LoginWithToken />} 
                />
                <Route 
                    path="/app" 
                    element={isAuthenticated ? <App /> : <Navigate to="/" />} 
                />
                <Route 
                    path="/pricing" 
                    element={ <Pricing /> } 
                />
                <Route 
                    path="/support" 
                    element={ <Support /> } 
                />
                <Route 
                    path="/terms" 
                    element={ <TermsAndPrivacy /> } 
                />
            </Routes>
        </Router>
    );
};

export default AppRouter;
